@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", "sans-serif";
  margin: 0;
}

.root {
  display: flex;
  flex-direction: column;
}

.movie {
  background-color: #3f51b5;
  border-radius: 3px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 1rem;
  width: 300px;
  position: relative;
  cursor: pointer;
}

.movie img {
  object-fit: cover;
  height: 450px;
  max-width: 100%;
}

.movie-over {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  color: #000;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  max-height: 100%;
  padding: 1rem;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.movie:hover .movie-over {
  transform: translateX(0%);
}

.tag {
  border-radius: 5px;
  padding: 0.7rem;
  font-weight: bold;
}

.tag.green {
  background-color: #1b5e20;
}

.tag.orange {
  background-color: #f57f17;
}

.tag.red {
  background-color: #7f0000;
}

.search {
  width: 100%;
  justify-content: center;
  background-color: #bdbdbd;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.search-input {
  height: 30px;
  width: 300px;
  border-radius: 5px;
  outline: none;
  border: none;
}

.navbar {
  padding: 1rem 3rem;
  background-color: #0d47a1 !important;
}

.form-image {
  min-width: 800px;
  min-height: 800px;
}

.register-form {
  flex: 1;
  background-color: #eeeeee;
  color: #000000;
  padding: 5rem;
}

.form-title {
  text-align: center;
  margin-bottom: 3rem;
}

.form-label {
  padding-left: 1.5rem;
  margin-bottom: 0px;
  font-size: 1.5rem;
}

.form-control {
  width: 90%;
  margin: 20px;
}

.btn-primary {
  margin-left: 1.5rem;
}

.link {
  color: #0b5ed7;
  text-decoration: underline;
  margin-left: 1.5rem;
  cursor: pointer;
}
